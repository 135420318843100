/* Custom Zulip icons generated with ./tools/setup/generate-custom-icon-webfont */

@font-face {
    font-family: 'zulip-icons';
    src: url("fonts/zulip-icons.eot?861dcded322d3b3b822de184d7f1ec85?#iefix") format("embedded-opentype"),
url("fonts/zulip-icons.woff2?861dcded322d3b3b822de184d7f1ec85") format("woff2"),
url("fonts/zulip-icons.woff?861dcded322d3b3b822de184d7f1ec85") format("woff");
    font-weight: normal;
    font-style: normal;
}

i.zulip-icon {
    font-family: 'zulip-icons' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none;
    font-size: inherit;
    line-height: 100%;

    text-decoration: inherit;
    text-rendering: auto;
    display: inline-block;
    speak: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.zulip-icon::before {
    font-family: 'zulip-icons' !important;
    font-style: normal;
    font-weight: normal !important;
    vertical-align: top;
}

.zulip-icon.bot::before {
    content: "\f101";
}
